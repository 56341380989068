import {ApiTarget} from 'Types/api';
import api from 'Utility/api';
import {loadFeatureFlags} from 'Utility/featureFlags';
import {writable} from 'svelte/store';

type Variables = {
  SVELTE_APP_API_HOST: string;
  SVELTE_APP_STRIPE_PUBLIC_KEY: string;
  SVELTE_APP_AMPLITUDE_CLIENT_KEY: string;
  email_host: string;
  SVELTE_APP_DATADOG_RUM_APPLICATION_ID: string;
  SVELTE_APP_DATADOG_RUM_CLIENT_TOKEN: string;
  SVELTE_APP_DATADOG_TOKEN: string;
  STATIC_PAGE_TERMS_AND_CONDITIONS: string;
  PUBLIC_DOMAINS: string;
  DROPBOX_SIGN_SKIP_DOMAIN_VERIFICATION: string;
  recaptcha_threshold: string;
  GOOGLE_GEOCODER_API_KEY: string;
};

export const environmentVariables = writable<Variables>(undefined);

type AppSettingsResponse = {
  variables: Variables;
};

export const loadAppSettings = async (): Promise<void> => {
  const variables = await getAppSettings();
  environmentVariables.set(variables);

  await loadFeatureFlags([
    'ownerless_profiles',
    'enable_ttw',
    'new_platform_emails',
  ]);
};

const getAppSettings = async (): Promise<Variables> => {
  const response = await api.getJson<AppSettingsResponse>(
    ApiTarget.V1,
    'app_settings'
  );
  return response.variables;
};
