import type {FeatureFlags} from 'Store/featureFlagStore';
import {featureFlagStore} from 'Store/featureFlagStore';
import {get} from 'svelte/store';
import {ApiTarget} from 'Types/api';
import api from 'Utility/api';

type FeatureFlagsResponse = {
  flags: FeatureFlags;
};

export const isFeatureFlagEnabled = (flag: string): boolean => {
  return !!get(featureFlagStore)[flag];
};

export const loadFeatureFlags = async (flags: string[]) => {
  try {
    const payload = {
      flags_keys: flags,
    };

    const url = 'feature_flags';
    const response = (await api.postJson(
      ApiTarget.V1,
      url,
      payload
    )) as FeatureFlagsResponse;

    featureFlagStore.set(response.flags);
  } catch (error) {
    featureFlagStore.set({});
    throw new Error('Error fetching feature flags');
  }
};
